import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/home/runner/work/core/core/apps/videos-admin/src/theme/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/material/CssBaseline/CssBaseline.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/core/core/node_modules/@mui/material/styles/styled.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/material/styles/ThemeProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/core/core/node_modules/@mui/material/styles/ThemeProviderWithVars.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/material/styles/useTheme.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/material/styles/useThemeProps.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/styled-engine/GlobalStyles/GlobalStyles.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/styled-engine/StyledEngineProvider/StyledEngineProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/system/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/system/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/system/createBox/createBox.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/system/cssVars/useCurrentColorScheme.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/core/core/node_modules/@mui/system/GlobalStyles/GlobalStyles.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/core/core/node_modules/@mui/system/Grid/Grid.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/core/core/node_modules/@mui/system/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/system/ThemeProvider/ThemeProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/core/core/node_modules/@mui/system/useMediaQuery/useMediaQuery.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/core/core/node_modules/@mui/system/useTheme/useTheme.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/system/useThemeProps/useThemeProps.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/system/useThemeWithoutDefault/useThemeWithoutDefault.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/utils/esm/useControlled/useControlled.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/utils/esm/useEnhancedEffect/useEnhancedEffect.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/utils/esm/useEventCallback/useEventCallback.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/utils/esm/useForkRef/useForkRef.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/utils/esm/useId/useId.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/utils/esm/useLazyRef/useLazyRef.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/utils/esm/useOnMount/useOnMount.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/utils/esm/usePreviousProps/usePreviousProps.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/core/core/node_modules/@mui/utils/esm/useSlotProps/useSlotProps.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/core/core/node_modules/@mui/utils/esm/useTimeout/useTimeout.js");
